
.component-create-field-form {
  > :first-child {
    display: flex;
    align-items: center;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .custom-radio {
    display: flex;

    > * {
      flex: 1;
      text-align: center;
    }
  }
}
