
.page-model-setup .editable-table .record {
  height: 40px;
  line-height: 40px;

  display: flex;

  > .field {
    flex: 0 0 190px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
  }

  > .field.is-id {
    flex: 0 0 80px;
  }

  > .actions {
    min-width: 100px;
    padding-left: 10px;
    display: flex;

    > * {
      color: #ff662e;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.page-model-setup .editable-table .record.is-editing {
  > .field {
    padding-left: 0;
  }
}

.page-model-setup .editable-table .record:not(:last-child) {
  border-bottom: 1px solid #EAEAEA;
}
