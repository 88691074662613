
.component-update-field-form {
  > :first-child {
    display: flex;
    align-items: center;
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
}
