
.page-data-tables .editable-table .record {
  height: 40px;
  line-height: 40px;

  display: flex;

  > .field {
    flex: 0 0 120px;
    padding-left: 10px;
  }

  > .actions {
    padding-left: 10px;
    display: flex;

    > * {
      color: #ff662e;
      margin-right: 10px;
      cursor: pointer;
    }
  }
}

.page-data-tables .editable-table .record.is-editing {
  > .field {
    padding-left: 0;
  }
}

.page-data-tables .editable-table .record:not(:last-child) {
  border-bottom: 1px solid #EAEAEA;
}
