
.container {
  min-width: 1040px;

  .nav-bar {
    height: 60px;
    background-color: RGB(55, 66, 80);

    display: flex;
    align-items: center;

    img {
      margin-left: 30px;
      height: 30px;
      width: auto;
    }
  }

  > .content {
    margin-top: 20px;
    padding: 0 20px;
  }
}

.container.password {
  min-width: 500px;
  width: 500px;
  margin: 0 auto;
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > :first-child {
    width: 500px;
    margin-bottom: 20px;
  }

  > :last-child {
    width: 100px;
  }
}

.popover-param-item {
  height: 32px;
  line-height: 32px;
  width: 100px;
  cursor: pointer;
  padding-left: 5px;

  &:hover {
    background-color: #D0D0D0;
  }
}