
.component-formula-editor {
  width: 100%;
  height: 220px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #E0E0E0;

  display: flex;
  flex-direction: column;

  .body {
    flex: 1;
  }

  .title, .body {
    display: flex;

    > :first-child {
      flex: 0 0 60px;
    }

    > :last-child {
      flex: 1;
    }
  }

  > *:not(:last-child) {
    margin-bottom: 10px;
  }

  .actions {
    > * {
      flex: 0 0 80px;
      margin-right: 10px;
    }
  }
}

.component-formula-editor:hover {
  border-color: RGB(55, 66, 80);
}

.component-formula-editor.is-editing {
  .title, .body {
    > div:first-child {
      margin-top: 5px;
      flex: 0 0 50px;
    }
  }
}

.component-formula-editor.is-reading {
  cursor: pointer;

  .title {
    margin-top: 5px;
  }
  
  .body {
    flex: 0 1;
    margin-top: 10px;
    display: flex;
    align-items: center;
  }

  .formula-display {
    width: 100%;
    display: flex;
    align-items: center;

    div[data-param-type="input-param"] {
      border: 1px solid #E0E0E0;
      background-color: #E0E0E0;
      padding: 5px 10px;
      margin: 0 10px;
    }

    div[data-param-type="input-param"]:first-child {
      margin-left: 0;
    }
  }
}
