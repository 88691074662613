
.page-model-setup {
  display: flex;

  .model-list {
    flex: 0 0 200px;
    border-right: 1px solid #D0D0D0;

    .model-list-item {
      height: 45px;
      width: 100%;
      padding-left: 15px;
      line-height: 45px;
    }

    .model-list-item:hover {
      background-color: #F5F5F5;
    }

    .model-list-item.selected {
      background-color: #EAEAEA;
    }

    button {
      margin-left: 5px;
      margin-top: 15px;
    }
  }

  .model-details {
    flex: 1;
  }
}

.page-model-setup .model-details {
  flex: 1;

  padding: 20px;

  > .header {
    margin-bottom: 20px;

    display: flex;
    justify-content: space-between;

    > .title {
      flex: 1;
      padding-left: 5px;
      height: 35px;
      line-height: 35px;

      font-size: 18px;

      display: flex;

      > div:first-child {
        flex: 0 0 80px;
      }
    }
  }

  > .editable-table {
    margin-bottom: 50px;
  }

  > .editable-table > .title {
    margin-bottom: 10px;
    padding-left: 5px;

    font-size: 16px;
  }

  > .editable-table .header {
    height: 35px;

    background-color: #EAEAEA;
    font-weight: 700;
    line-height: 35px;

    display: flex;
    justify-content: flex-start;

    > div {
      flex: 0 0 190px;
      padding-left: 10px;
    }
  }

  > .editable-table .header {
    > *:first-child {
      flex: 0 0 80px;
    }
  }

  > .editable-table > .actions {
    margin-top: 10px;
  }
}
