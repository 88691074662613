
.page-data-tables {
  display: flex;

  .table-list {
    flex: 0 0 200px;
    border-right: 1px solid #D0D0D0;

    .table-list-item {
      height: 45px;
      width: 100%;
      padding-left: 15px;
      line-height: 45px;
    }

    .table-list-item:hover {
      background-color: #F5F5F5;
    }

    .table-list-item.selected {
      background-color: #EAEAEA;
    }

    button {
      margin-left: 5px;
      margin-top: 15px;
    }
  }

  .table-details {
    flex: 1;

    padding: 20px;

    > .header {
      margin-bottom: 10px;

      display: flex;
      justify-content: space-between;

      > .title {
        flex: 1;
        padding-left: 5px;
        height: 35px;
        line-height: 35px;
  
        font-size: 18px;
  
        display: flex;
  
        > div:first-child {
          flex: 0 0 60px;
        }
      }
    }

    > .description {
      padding-left: 5px;
      margin-bottom: 10px;
    }

    > .editable-table .header {
      height: 35px;

      background-color: #EAEAEA;
      font-weight: 700;
      line-height: 35px;

      display: flex;
      justify-content: flex-start;

      > .field {
        flex: 0 0 120px;
        padding-left: 10px;

        cursor: pointer;
      }

      > .field:hover {
        background-color: #D0D0D0;
      }

      > .add {
        padding-left: 10px;
        font-size: 18px;
      }
    }

    > .editable-table > .actions {
      margin-top: 10px;
    }
  }
}

.page-data-tables.empty {
  display: block;

  > :first-child {
    margin-bottom: 20px;
  }
}