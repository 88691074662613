
.component-edit-text {
  width: 400px;

  display: flex;
  align-items: stretch;

  > * {
    margin-right: 5px;
  }

  > .read-display {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: #A0A0A0;
  }

  > .submit, > .cancel {
    flex: 0 0 50px;
    height: 35px;
  }
}
