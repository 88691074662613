
.page-calculator {
  display: flex;

  .model-list {
    flex: 0 0 200px;
    border-right: 1px solid #D0D0D0;

    .model-list-item {
      height: 45px;
      width: 100%;
      padding-left: 15px;
      line-height: 45px;
    }

    .model-list-item:hover {
      background-color: #F5F5F5;
    }

    .model-list-item.selected {
      background-color: #EAEAEA;
    }

    button {
      margin-left: 5px;
      margin-top: 15px;
    }
  }

  .calculator-details {
    flex: 1;
  }
}

.page-calculator .calculator-details {
  flex: 1;

  padding: 0 20px;
  padding-top: 20px;

  .actions {
    margin-top: 20px;
  }
}

.page-calculator .result {
  .formula-display {
    width: 100%;
    display: flex;
    align-items: center;

    div[data-param-type="input-param"] {
      border: 1px solid #E0E0E0;
      background-color: #E0E0E0;
      padding: 5px 10px;
      margin: 0 10px;
    }

    div[data-param-type="input-param"]:first-child {
      margin-left: 0;
    }
  }

  .divider {
    margin: 10px 0;
    border: 1px solid #E0E0E0;
  }

  .content {
    .title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .formula {
      margin-bottom: 20px;
    }

    .result-row {
      margin-bottom: 5px;

      display: flex;
      align-items: center;

      > :first-child {
        flex: 0 0 80px;
        margin-right: 10px;
      }
    }

    .quote {
      color: red;
    }
  }
}